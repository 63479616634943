import React from "react";
import { Link } from "gatsby"
import LogotypeImg from "../images/99rises-logotype-350x102px.png"
import {
    Typography,
    Box,
    OutlinedInput,
    makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    'btn input': {
        padding: '4px'
    }
}));

export default function Thankyou(props) {
    const classes = useStyles();
    return (
        <div className="container">
            <Box mt={10}><img src={LogotypeImg}></img>

                <Box my={2}><Typography variant="h4">Thank you for joining the waitlist!</Typography></Box>
                <Box my={2}><Typography variant="h6">You can sign-up for our weekly newsletter <Link to="/" color="green">here</Link></Typography></Box>
                <Box mt={4} mb={2}><Typography variant="h6">Please share with your family, friends and co-workers.</Typography></Box>
                <Typography align="center" variant="body1">share this URL:</Typography>
                <Box mt={3} mb={1}>
                    <OutlinedInput size="small" value="http://waitlist.99rises.com/?ref=f3glmu7jsz46edqanqq9fewdp" readOnly />
                </Box>
                <div>© 2021 99rises. All rights reserved</div>
            </Box>
        </div>
    );
};
